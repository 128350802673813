<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col>
        <el-breadcrumb>
          <el-breadcrumb-item class="pointer" :to="{ path: '/grid' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('grid')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="grid"
                  ref="grid"
                  label-width="150px"
                  class="grid"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="网格名称" prop="gridName">
                        <el-input v-model="grid.gridName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="省" prop="streetCode">
                        <el-select
                          v-model="grid.provinceCode"
                          @change="changeProvince"
                          placeholder="请选择省"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in provinceList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="市" prop="cityCode">
                        <el-select
                          v-model="grid.cityCode"
                          @change="changeCity"
                          placeholder="请选择城市"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in cityList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="区县" prop="districtCode">
                        <el-select
                          v-model="grid.districtCode"
                          @change="changeDistrict"
                          placeholder="请选择区"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in districtList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="街道名称" prop="streetCode">
                        <el-select
                          v-model="grid.streetName"
                          @change="changeStreet"
                          placeholder="请选择街道"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in streetList"
                            :key="item.id"
                            :label="item.streetName"
                            :value="item.id + ''"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="社区名称" prop="streetCode">
                        <el-select
                          v-model="grid.communityName"
                          @change="changeCommunity"
                          placeholder="请选择社区"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in communityList"
                            :key="item.communityCode"
                            :label="item.communityName"
                            :value="item.communityCode"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="小区名称" prop="streetCode">
                        <el-select
                          v-model="grid.villageName"
                          placeholder="请选择小区"
                          class="wp100"
                          @change="changeVillage"
                        >
                          <el-option
                            v-for="item in villageList"
                            :key="item.villageCode"
                            :label="item.villageName"
                            :value="item.villageCode"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="楼栋" prop="">
                        <el-select
                          v-model="grid.saveVillageBuildList"
                          multiple
                          placeholder="请选择楼栋"
                        >
                          <el-option
                            v-for="item in villageBuildList"
                            :key="item.id"
                            :label="item.buildName"
                            :value="item.buildCode"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="网格管理员" prop="">
                        <el-select
                          v-model="grid.saveGridManageList"
                          multiple
                          filterable
                          remote
                          reserve-keyword
                          placeholder="请输入关键词"
                          :remote-method="getGridManageList"
                          :loading="loading"
                        >
                          <el-option
                            v-for="item in gridManageList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.gridManageCode"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="检索" prop="检索">
                        <el-input id="tipinput" v-model="name"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="经度" prop="longitude">
                        <el-input v-model="grid.longitude"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="纬度" prop="latitude">
                        <el-input v-model="grid.latitude"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="24">
                      <el-form-item label="地图位置" prop="地图位置">
                        <div id="containerAdd"></div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>

                <div style="margin-left: 650px">楼栋列表</div>
                <el-table
                  :data="tableData"
                  border
                  fit
                  :height="height"
                  highlight-current-row
                  style="width: 100%"
                  @sort-change="sortChange"
                >
                  <el-table-column
                    label="街道名称"
                    align="center"
                    prop="街道名称"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.streetName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="社区名称"
                    align="center"
                    prop="社区名称"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.communityName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="小区名称"
                    align="center"
                    prop="小区名称"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.villageName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="楼栋名称"
                    align="center"
                    prop="楼栋名称"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.buildName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="创建时间"
                    align="center"
                    prop="创建时间"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.createDt | dataFormat }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding fixed-width"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-delete textblue f16 ml20"
                          @click="del(scope.row.id)"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="queryInfo.currPage"
                  :page-sizes="[10, 20, 30, 50]"
                  :page-size="queryInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                ></el-pagination>
              </div>

              <div style="margin-left: 650px">网格管理员列表</div>
              <el-table
                :data="tableDataAdmin"
                border
                fit
                :height="height"
                highlight-current-row
                style="width: 100%"
                @sort-change="sortChange"
              >
                <el-table-column label="微信昵称" align="center" prop="类型">
                  <template slot-scope="scope">
                    <span>{{ scope.row.gridManageMemberName }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="电话" align="center" prop="社区名称">
                  <template slot-scope="scope">
                    <span>{{ scope.row.gridManagePhone }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="类型" align="center" prop="类型">
                  <template slot-scope="scope">
                    <span v-if="scope.row.type == '1'">网格长</span>
                    <span v-if="scope.row.type == '2'">管理员</span>
                    <span v-if="scope.row.type == '3'">警务员</span>
                    <span v-if="scope.row.type == '4'">监督员_协管员</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  align="center"
                  class-name="small-padding fixed-width"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="删除"
                      placement="bottom"
                    >
                      <i
                        class="el-icon-delete textblue f16 ml20"
                        @click="delAdmin(scope.row.id)"
                      ></i>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChangeAdmin"
                @current-change="handleCurrentChangeAdmin"
                :current-page.sync="queryInfoAdmin.currPage"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="queryInfoAdmin.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalAdmin"
              ></el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 当前页数 
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      name: "",
      tabs: ["基本信息"],
      grid: {
        id: "",
        gridCode: "",
        gridName: "",
        streetCode: "",
        streetName: "",
        longitude: "",
        latitude: "",
        path: "",
        createDt: "",
        gridPerson: "",
        saveGridManageList: [], //选择的管理员列表
        provinceName: "",
        cityName: "",
        districtName: "",
        saveVillageBuildList: [], //选择的楼栋列表
        villageCode: "",
      },
      provinceList: [], //省列表
      cityList: [], //市列表
      districtList: [], //区县列表
      streetList: [], //  街道列表
      communityList: [], //社区列表
      villageList: [], //小区列表
      villageBuildList: [], //楼栋列表
      // 地图实例
      map: "",
      // 地图中心点
      center: [118.753537, 32.078067],
      zoom: 15,
      gridManageList: [], //网格管理员列表
      gridCode: "",
      // 获取楼栋列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          gridCode: this.$route.query.gridCode,
        },
      },
      queryInfoAdmin: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          gridCode: this.$route.query.gridCode,
        },
      },
      tableData: [],
      tableDataAdmin: [],
      total: 0,
      totalAdmin: 0,
      height: window.innerHeight - 256, //表格高度
    };
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: "/grid",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },

    initMap() {
      //地图加载
      var that = this;
      var map = new AMap.Map("containerAdd", {
        resizeEnable: true,
      });

      //输入提示
      var autoOptions = {
        input: "tipinput",
      };

      var auto = new AMap.Autocomplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
        map: map,
      });
      //构造地点查询类
      AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
      function select(e) {
        console.log(e);
        placeSearch.setCity(e.poi.adcode);
        placeSearch.search(e.poi.name); //关键字查询查询
        that.grid.latitude = e.poi.location.lat;
        that.grid.longitude = e.poi.location.lng;
      }
      AMap.event.addListener(map, "click", function (e) {
        // map.clearMap(); // 清除地图上所有添加的覆盖物
        // new AMap.Marker({
        //   position: e.lnglat,
        //   map: map,
        // });
        console.log(e.lnglat.getLng());
        console.log(e.lnglat.getLat());
        that.grid.latitude = e.lnglat.getLat();
        that.grid.longitude = e.lnglat.getLng();
      });
    },

    submitForm(formName) {
      var that = this;
      that.$http
        .post("/grid/saveGrid", that.grid)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.$router.go(-1);
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true,
          });
        });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/grid/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.grid = response.data.data;
              if (that.grid.provinceCode) {
                that.getCityList(that.grid.provinceCode);
              }
              if (that.grid.cityCode) {
                that.getDistrictList(that.grid.cityCode);
              }
              if (that.grid.districtCode) {
                that.getStreetList(that.grid.districtCode);
              }
              if (that.grid.streetCode) {
                that.getCommunityList(that.grid.streetCode);
              }
              if (that.grid.communityCode) {
                that.getVillageList(that.grid.communityCode);
              }
              if (that.grid.villageCode) {
                that.getVillageBuildList(that.grid.villageCode);
              }
              this.saveVillageBuildList = [];
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;
      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },

    changeProvince(e) {
      this.handleClearCode(1);
      this.getCityList(e);
    },
    changeCity(e) {
      this.handleClearCode(2);
      this.getDistrictList(e);
    },
    changeDistrict(e) {
      this.handleClearCode(3);
      this.getStreetList(e);
    },

    changeStreet(e) {
      this.grid.streetName = this.streetList.find((item) => {
        return item.streetCode === e;
      }).streetName;
      console.log(this.streetName);
      this.handleClearCode(4);
      this.getCommunityList(e);
    },

    changeCommunity(e) {
      this.handleClearCode(5);
      this.getVillageList(e);
    },

    changeVillage(e) {
      this.handleClearCode(6);
      this.getVillageBuildList(e);
    },
    changeVillageBuild(e) {},

    handleClearCode(level) {
      //重新选择时，清空级联列表
      switch (level) {
        case 1:
          this.grid.cityCode = "";
          this.grid.districtCode = "";
          this.grid.streetCode = "";
          this.grid.communityCode = "";
          this.grid.villageCode = "";
          this.grid.saveVillageBuildList = [];
          break;
        case 2:
          this.grid.districtCode = "";
          this.grid.streetCode = "";
          this.grid.communityCode = "";
          this.grid.villageCode = "";
          this.grid.saveVillageBuildList = [];
          break;
        case 3:
          this.grid.streetCode = "";
          this.grid.communityCode = "";
          this.grid.villageCode = "";
          this.grid.saveVillageBuildList = [];
          break;
        case 4:
          this.grid.communityCode = "";
          this.grid.villageCode = "";
          this.grid.saveVillageBuildList = [];
          break;
        case 5:
          this.grid.villageCode = "";
          this.grid.saveVillageBuildList = [];
          break;
        case 6:
          this.grid.saveVillageBuildList = [];
          break;
        default:
          break;
      }
    },

    //获取省
    getProvinceList() {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: 0 })
        .then(function (response) {
          if (response.data.code == 200) {
            that.provinceList = response.data.data;
            var id = response.data.data[0].id;
            that.getCityList(id);
          }
        });
    },
    //获取市
    getCityList(id) {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.cityList = response.data.data;
            var e = response.data.data[0].id;
            that.getDistrictList(e);
          }
        });
    },

    //获取区
    getDistrictList(id) {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.districtList = response.data.data;
            var e = response.data.data[0].id;
            that.getStreetList(e);
          }
        });
    },
    // 查询所有街道
    getStreetList(id) {
      let that = this;
      that.$http
        .post("/area/queryStreetList", {
          id,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.streetList = response.data.data.queryStreetList;
            console.log('街道数据',response.data)
          }
        });
    },
    //查询社区
    getCommunityList(streetCode) {
      let that = this;
      that.$http
        .post("/community/queryList", {
          streetCode: streetCode,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.communityList = response.data.data;
          }
        });
    },
    //查询小区
    getVillageList(communityCode) {
      let that = this;
      that.$http
        .post("/village/queryList", {
          communityCode: communityCode,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.villageList = response.data.data;
          }
        });
    },
    //查询楼栋
    getVillageBuildList(villageCode) {
      let that = this;
      that.$http
        .post("/villageBuild/queryList", {
          villageCode: villageCode,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.villageBuildList = response.data.data;
          }
        });
    },

    //查询网格管理员
    getGridManageList(phone) {
      let that = this;
      that.gridManageList = [];
      that.$http
        .post("/streetGridManage/queryGridManageListVague", {
          phone: phone,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.gridManageList = response.data.data;
          }
        });
    },
    //获取该网格楼栋列表
    getBuildList() {
      var that = this;
      that.$http
        .post("/villageBuild/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },

    getBuildListAdmin() {
      var that = this;
      that.$http
        .post("/grid/queryGridManageList", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.totalAdmin = response.data.data.count;
            that.queryInfoAdmin.currPage = response.data.data.currPage;
            that.tableDataAdmin = response.data.data.data;
          }
        });
    },

    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getBuildList();
    },
    handleSizeChangeAdmin(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getBuildListAdmin();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getBuildList();
    },
    handleCurrentChangeAdmin(newSize) {
      this.queryInfo.currPage = newSize;
      this.getBuildListAdmin();
    },

    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    },
    // 监听排序
    sortChange() {},
    // 删除楼栋
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/villageBuild/delete", { id: id })
          .then(function (response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.getBuildList();
          });
      }
    },

    async delAdmin(id) {
      debugger;
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/grid/delete", { id: id }).then(function (response) {
          that.$notify.success({
            title: "提示",
            message: "删除成功",
            showClose: true,
          });
          that.getBuildListAdmin();
        });
      }
    },
  },

  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    if (
      this.$route.query.gridCode != undefined &&
      this.$route.query.gridCode != ""
    ) {
      this.gridCode = this.$route.query.gridCode;
      this.getBuildList();
      this.getBuildListAdmin();
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.getProvinceList();
  },
  mounted() {
    var that = this;
    that.initMap();
    this.$el.querySelector(".menu-content").onscroll = () => {
      // that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
#containerAdd {
  width: 90%;
  height: 400px;
}
</style>
